/* eslint react-hooks/exhaustive-deps: "off" */

import { useEffect, useState } from "react";
import { Button, Container, Form, Table } from "react-bootstrap";
import { Bounce, toast } from 'react-toastify';

import api from "../api";
import { AvailableDate, SignupListItem } from "../types";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ConfirmationModal from "./ConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

function SignupList() {

    const [signupList, setSignupList] = useState<SignupListItem[]>([]);         // stores data from the API
    const [availableDates, setAvailableDates] = useState<AvailableDate[]>([]);  // stores data from the API

    const [showNewItem, setShowNewItem] = useState<boolean>(false);             // true to show new entry form
    const [apiInFlight, setApiInFlight] = useState<boolean>(false);             // true while API request is underway
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);   // true to show modal confirm dialog

    // **************** Toast convenience functions ****************

    const errorToast = (message: string, error: any) => {
        toast.error(message.replace(/\n/g, '<br />'), {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
            transition: Bounce
        });
        console.dir(error);
    }

    const successToast = (message: string) => {
        toast.success(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
            transition: Bounce
        });
    }

    // **************** API interaction functions ****************

    const updateDate = () => {
        // Fetch the list of available dates from the server
        setApiInFlight(true);
        api.get<AvailableDate[]>('date')
            .then(response => {
                setAvailableDates(response.data);
            })
            .catch(error => {
                errorToast("Could not reach the API to get the shopping list!", error);
            })
            .finally(() => {
                setApiInFlight(false);
            });        
    }
    const updateTopic = () => {
        // Fetch the topic list from the server
        setApiInFlight(true);
        api.get<SignupListItem[]>('topic')
            .then(response => {
                setSignupList(response.data);
            })
            .catch(error => {
                errorToast("Could not reach the API to get the shopping list!", error);
            })
            .finally(() => {
                setApiInFlight(false);
            });
    };

    const doAddNewItem = () => {
        const starId = (document.getElementById('form_starId') as HTMLInputElement).value;
        const selectedDate = (document.getElementById('form_date') as HTMLSelectElement).value;
        const topic = (document.getElementById('form_title') as HTMLInputElement).value;
        const reference = (document.getElementById('form_ref') as HTMLInputElement).value;
        const details = (document.getElementById('form_det') as HTMLTextAreaElement).value;

        const newItem: SignupListItem = {
            id: 0, // Assuming the ID is generated by the backend
            starId,
            selectedDate,
            topic,
            reference,
            details,
            submitted: null
        };

        console.dir(newItem);
        // Attempt to submit the item
        addNewItem(newItem);
    }

    const addNewItem = (item: SignupListItem) => {
        // Add new shopping list item
        setApiInFlight(true);
        api.put('topic', item)
            .then(response => {
                updateTopic(); updateDate();
                setShowNewItem(false);
                successToast("Your topic choice has been successfully entered!")
            })
            .catch(error => {
                const { status, data } = error.response || {};
                console.error(`API error ${status}: ${data}`);
                errorToast(`Error ${status}: ${data}`,error);
                // 403 errors are considered unrecoverable, so close the form.
                // Also clear all of the form fields.
                if (status === 403) {
                    setShowNewItem(false);
                    (document.getElementById('form_starId') as HTMLInputElement).value = '';
                    (document.getElementById('form_date') as HTMLSelectElement).value = '';
                    (document.getElementById('form_title') as HTMLInputElement).value = '';
                    (document.getElementById('form_ref') as HTMLInputElement).value = '';
                    (document.getElementById('form_det') as HTMLTextAreaElement).value = '';
                }
            })
            .finally(() => {
                setApiInFlight(false);
            });
    }

    // Gets the list from the API on page load.
    useEffect(() => {
        updateTopic();
        updateDate();
    }, []);

    return (
        <>
            <Container>
                <div style={{textAlign: "right", paddingBottom: "1em"}}>
                    {apiInFlight && <><span>Waiting for API...</span> <span><i className="fa-solid fa-hourglass" /></span><span style={{display: "inline-block", width: "3em"}}></span></>}&nbsp;
                    <Button disabled={showNewItem || apiInFlight} variant="success" onClick={() => setShowNewItem(true)}><i className="fa-regular fa-plus" /> Sign Up</Button>
                </div>

                {showNewItem && <>
                    <Container>
                        <p className="signupHead">Sign Up for a Presentation</p>

                        <Table variant="dark">
                            <tbody>
                                <tr>
                                    <td style={{ width: "20%" }}><label htmlFor="form_starId">Your StarID:</label></td>
                                    <td><Form.Control disabled={apiInFlight} as="input" maxLength={8} id="form_starId" type="text" placeholder="Enter StarID" style={{ width: "16ch" }} /></td>
                                </tr>
                                <tr>
                                    <td style={{ width: "20%" }}><label htmlFor="form_starId">Presentation Date:</label></td>
                                    <td>
                                        <Form.Select disabled={apiInFlight} id="form_date" defaultValue={""} aria-label="Select Presentation Date" className="bg-dark text-white">
                                            <option value="" disabled>Select a date</option>

                                            {availableDates.map((item, index) => (
                                                <option key={item.date} value={item.date} disabled={item.slots === 0}>
                                                    {new Date(item.date).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric', timeZone: 'Etc/UTC' })} ({item.slots} slot{item.slots !== 1 && 's'})
                                                </option>

                                ))} 

                                        </Form.Select>
                                    </td>
                                </tr>
                                <tr>
                                    <td><label htmlFor="form_title">Presentation Topic</label></td>
                                    <td>
                                        <Form.Control disabled={apiInFlight} as="input" maxLength={255} id="form_title" type="text" placeholder="Topic" /><br />
                                        <p className="small-text">Enter a brief topic. Example: 'National Public Data breach - implications for privacy'</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td><label htmlFor="form_ref">Reference</label></td>
                                    <td>
                                        <Form.Control disabled={apiInFlight} as="input" maxLength={255} id="form_ref" type="text" placeholder="Reference URL or Citation" /><br />
                                        <p className="small-text">Enter a primary source for your presentation, such as a news article, podcast episode, YouTube video, etc. In most cases, provide a URL.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td><label htmlFor="form_det">Details (optional)</label></td>
                                    <td>
                                        <Form.Control disabled={apiInFlight} as="textarea" rows={3} id="form_det" placeholder="Details" /><br />
                                        <p className="small-text">Enter some brief details about your presentation. No need to include a lot of detail, but include enough information so that others can determine both which security topic as well as what specific aspect of it you are covering. For example: "I'll be covering the NPD breach and the implications that it will have on many people. NPD's breach includes PII for a vast majority of Americans, which may present some unique challenges."</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ textAlign: "center" }}>
                                        <Button disabled={apiInFlight} variant="success" onClick={() => setShowConfirmModal(true)}><FontAwesomeIcon icon={faCheck} /> Submit</Button>
                                        &nbsp;
                                        <Button disabled={apiInFlight} variant="danger" onClick={() => setShowNewItem(false)}><FontAwesomeIcon icon={faTimes} /> Cancel</Button>
                                    </td>
                                </tr>
                            </tbody>

                        </Table>
                    </Container>
                </>}
                <Table variant="dark" striped>
                    <thead>
                        <tr>
                            <th>Date Submitted</th>
                            <th>Presentation Date</th>
                            <th style={{width: "75%"}}>Topic</th>
                        </tr>
                    </thead>
                    <tbody>
                        {signupList.length === 0 && <>
                            <tr>
                                <td colSpan={3}>Nobody has signed up yet - why not be the first!</td>
                            </tr>
                        </>}
                        {/* <tr>
                            <td>2024-09-01 10:00:00 GMT</td>
                            <td>September 2</td>
                            <td>
                                <p><strong>A Big Data Breach</strong></p>
                                <p>Reference: https://www.google.com</p>
                                <p className="left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </td>
                        </tr> */}
                        {signupList.map((item: SignupListItem, index) => (
                            <tr key={item.id}>
                                <td>{new Date(item.submitted!).toLocaleString('en-US', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    second: 'numeric',
                                    hour12: true,
                                    timeZone: 'Etc/UTC'
                                })}</td>
                                <td>{new Date(item.selectedDate).toLocaleString('en-US', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    timeZone: 'Etc/UTC'
                                })}</td>
                                <td>
                                    <p><strong>{item.topic}</strong></p>
                                    <p>Reference: {item.reference}</p>
                                    <p>{item.details!.split('\n').map((line, index) => (
                                        <span key={index}>
                                            {line}
                                            <br />
                                        </span>
                                    ))}</p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
            
            <ConfirmationModal show={showConfirmModal} setShow={setShowConfirmModal} onClose={doAddNewItem} />
        </>
    )
}

export default SignupList;