/* 
 s* This code allows for global configuration variables in React.
 * The usual strategies, like .env files, only apply at build time, not runtime.
 * Therefore, it makes it nigh on impossible to change the values of the settings without *rebuilding the application from source.*
 * This may simply be impractical or not even possible for business apps without source available!
 *
 * This strategy reads the configuration variables from some mutable source at application *runtime*.
 * In development, Node lets us read the system environment variables in real time so we can simply use them as is.
 * In production, a web server can't read the environment variables since JavaScript executes client-side and is immutable on the server side.
 * Therefore, the strategy here is to inject the environment variables into the global Window object via an autogenerated Javascript file,
 *   which is then referenced in the index file. 
 * The variables can then be plucked off the Window object and used in the same way as Node can use the dynamic system environment variables.
 * 
 * The end result is that we can dynamically change the values of these variables in a React application at runtime without recompiling.
 */ 

// Update the global window object to include the environment variables
declare global {
  interface Window {
    env: {
      REACT_APP_API_URL: string;
      REACT_APP_INSTANCE_NAME: string;
    };
  }
}

// set the API URL based on environment variable
export const API_URL = process.env.NODE_ENV === 'production' ? window.env.REACT_APP_API_URL : process.env.REACT_APP_API_URL;
